import { isEmpty } from 'lodash';
import React from 'react';

export const formatDate = ( dateString ) => {
  // https://css-tricks.com/everything-you-need-to-know-about-date-in-javascript/

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const days = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ];
  
  const gigYear = dateString.slice(0, 4);
  const gigMonth = dateString.slice(5, 7) - 1;
  const gigDay = dateString.slice(8, 10);

  const d = new Date(gigYear, gigMonth, gigDay);
  const year = d.getFullYear();
  const date = d.getDate();

  const monthName = months[d.getMonth()];
  // const dayName = days[d.getDay()];

  const formatted = `${monthName} ${date}, ${year}`

  return formatted;
}

// Pull from Contentful API
export const renderGigs = (gigsList) => {
  if ( isEmpty( gigsList ) ) {
    return (
      <p className="upcoming-message">There are currently no upcoming gigs at this time. Check back soon for new dates!</p>
    )
  } else {
    return (
      <ul>
      {
      gigsList.map(gig => {
        const { gigDate, venueTime, gigTitle, gigLocation, gigTime } = gig.fields;
        const { id } = gig.sys;

        return (
          <li key={id}>
            <div aria-hidden></div>
            <h3>{formatDate(gigDate)}</h3>
            <p>{venueTime}</p>
            <p>{gigLocation}</p>
            <p>{gigTime}</p>
            <p>{gigTitle}</p>
          </li>

        )
      })
      }
    </ul>
    )
  }
}
